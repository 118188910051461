import { OfferType, ValueOfferSubtype, TeeOfferSubtype } from '@super-protocol/sdk-js';
import {
  Offer, TeeOffer, HardwareInfo,
  OfferSlot,
  TeeOfferSlot,
  TeeOfferOption,
} from 'generated/types';
import {
  BuildOrderForm, FormContentKey, Slots,
} from 'lib/features/createOrderV2/types';
import { BigNumber } from 'bignumber.js';
import {
  getFieldBuildOrderdByOfferType, getSelectedSlot, checkAddContent, getOfferPriceBySlots,
} from 'lib/features/createOrderV2/helpers';
import {
  getTableDate, boolToStr, capitalize,
} from 'utils';
import {
  getCategoriesByConfiguration, getTeeOfferSubTypeName, getValueOfferSubTypeName, getFixedDeposit,
} from 'utils/sdk/utils';
import {
  convertSlotInfo, convertOptionData, convertSlotUsageInfo, priceTypeMap,
} from 'utils/slots';
import { LeftProps, Configuration } from './Left/types';

export const fieldsMap = {
  subType: 'Offer Type:',
  id: 'Offer ID:',
  provider: 'Provider:',
  published: 'Published:',
  disabledAfter: 'Disabled After:',
  updated: 'Updated:',
  cancelable: 'Cancelable:',
  categories: 'Category:',
};

export interface Data {
  common: {
    price: {
      type: string;
      sum: string;
    }[];
    restrictions: string[];
    fieldBuildOrderForm?: FormContentKey;
    selectedSlots?: Slots | null;
    id: string;
    isAdded: boolean;
  };
  left: LeftProps;
  right: Record<string, any>;
}

const sortSlots = (slotA: OfferSlot | TeeOfferSlot, slotB: OfferSlot | TeeOfferSlot) => {
  const { priceType: priceTypeA, price: priceA } = slotA?.usage || {};
  const { priceType: priceTypeB, price: priceB } = slotB?.usage || {};
  if (priceTypeA < priceTypeB) return -1;
  if (priceTypeA > priceTypeB) return 1;
  const priceABn = new BigNumber(priceA);
  const priceBBn = new BigNumber(priceB);
  if (priceABn.isGreaterThan(priceBBn)) return 1;
  if (priceBBn.isGreaterThan(priceABn)) return -1;
  return 0;
};

const getOfferSlots = (slots?: OfferSlot[]) => {
  return ([...(slots || [])])
    .sort(sortSlots)
    .map((slot) => ({
      id: {
        label: 'slotId',
        value: slot?.id,
        id: slot?.id,
      },
      title: {
        label: priceTypeMap[slot?.usage?.priceType] || '',
        value: `${getFixedDeposit({ deposit: slot?.usage?.price })} TEE`,
        id: slot?.usage?.priceType,
      },
      list: [
        convertSlotInfo({ slotInfo: slot?.info, keys: ['cpuCores', 'ram','gpuCores', 'vram'] }),
        [
          ...convertSlotInfo({ slotInfo: slot?.info, keys: ['diskUsage'] }),
          ...convertOptionData({
            optionInfo: slot?.option ? [{ option: slot.option, count: 1 }] : [],
            keys: ['bandwidth', 'traffic', 'externalPort'],
          })
        ],
        convertSlotUsageInfo({ slotUsage: slot?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
      ],
    }));
};

export const offerDataTransform = (
  data: Offer,
  buildOrderForm: BuildOrderForm,
): Data | undefined => {
  if (!data) return undefined;

  const {
    id, offerInfo, providerInfo, origins, disabledAfter, slots, configuration,
  } = data;
  const field = getFieldBuildOrderdByOfferType(offerInfo?.subType as ValueOfferSubtype, 'ValueOfferSubtype');
  const selectedSlots = getSelectedSlot(buildOrderForm, field, id);

  const categories = getCategoriesByConfiguration(configuration, offerInfo.subType as ValueOfferSubtype);

  const isAdded = checkAddContent({
    formContent: buildOrderForm,
    field,
    offerId: id,
  });

  const subType = getValueOfferSubTypeName(offerInfo.subType as ValueOfferSubtype);

  return {
    common: {
      price: getOfferPriceBySlots(selectedSlots, slots),
      restrictions: offerInfo?.restrictions?.offers || [],
      fieldBuildOrderForm: field,
      id,
      selectedSlots,
      isAdded,
    },
    left: {
      name: offerInfo.name,
      about: offerInfo.description,
      restricted: offerInfo.restrictions?.offers || [],
      type: offerInfo.offerType as OfferType,
      slots: getOfferSlots(slots),
      subType,
      options: [],
    },
    right: {
      id,
      subType,
      ...(offerInfo.subType !== ValueOfferSubtype.ValueSubtypeEngine ? { categories } : {}),
      provider: providerInfo.name,
      published: getTableDate(origins?.createdDate),
      ...(disabledAfter ? { disabledAfter: disabledAfter?.toString() as string } : {}),
      updated: getTableDate(origins?.modifiedDate),
    },
  };
};

export const getTEEConfiguration = (hardwareInfo: HardwareInfo): Configuration => {
  if (!hardwareInfo) return { slots: [], options: [] };
  const { slotInfo, optionInfo } = hardwareInfo || {};
  return {
    slots: convertSlotInfo({ slotInfo }),
    options: convertOptionData(optionInfo ? { optionInfo: [{ option: optionInfo, count: 1 }] } : {}),
  };
};

const getTeeOfferSlots = (slots?: TeeOfferSlot[]) => {
  return ([...(slots || [])])
    .sort(sortSlots)
    .map((slot) => ({
      id: {
        label: 'slotId',
        value: slot?.id,
        id: slot?.id,
      },
      title: {
        label: priceTypeMap[slot?.usage?.priceType] || '',
        value: `${getFixedDeposit({ deposit: slot?.usage?.price })} TEE`,
        id: slot?.usage?.priceType,
      },
      list: [
        convertSlotInfo({ slotInfo: slot?.info, keys: ['cpuCores', 'ram', 'diskUsage'] }),
        convertSlotInfo({ slotInfo: slot?.info, keys: ['gpuCores', 'vram'] }),
        convertSlotUsageInfo({ slotUsage: slot?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
      ],
    }));
};

const getTeeOfferOptions = (options?: TeeOfferOption[]) => {
  return (options || [])
    .map((option) => ({
      id: {
        label: 'optionId',
        value: option?.id,
        id: option?.id,
      },
      title: {
        label: priceTypeMap[option?.usage?.priceType] || '',
        value: `${getFixedDeposit({ deposit: option?.usage?.price })} TEE`,
        id: option?.usage?.priceType,
      },
      list: [
        convertOptionData({
          optionInfo: option?.info ? [{ option: option.info, count: 1 }] : [],
          keys: ['bandwidth', 'traffic', 'externalPort'],
        }),
        convertSlotUsageInfo({ slotUsage: option?.usage, keys: ['minTimeMinutes', 'maxTimeMinutes'] }),
      ],
    }));
};

export const teeOfferDataTransform = (
  data: TeeOffer | undefined,
  buildOrderForm?: BuildOrderForm,
  slotsFromQuery?: Slots | null,
): Data | undefined => {
  if (!data) return undefined;

  const {
    id, teeOfferInfo, providerInfo, origins, disabledAfter, slots, options,
  } = data;
  const { hardwareInfo } = teeOfferInfo || {};
  const field = getFieldBuildOrderdByOfferType(teeOfferInfo?.subType as TeeOfferSubtype, 'TeeOfferSubtype');

  const isAdded = checkAddContent({
    teeOfferId: id,
    field,
    formContent: buildOrderForm,
    slots: slotsFromQuery,
  });

  const subType = getTeeOfferSubTypeName(teeOfferInfo?.subType as TeeOfferSubtype);

  return {
    common: {
      price: getOfferPriceBySlots(slotsFromQuery, slots, options),
      restrictions: [],
      id,
      fieldBuildOrderForm: field,
      selectedSlots: slotsFromQuery,
      isAdded,
    },
    left: {
      name: teeOfferInfo.name,
      about: teeOfferInfo.description,
      restricted: [],
      type: OfferType.TeeOffer,
      slots: getTeeOfferSlots(slots),
      options: getTeeOfferOptions(options),
      configuration: getTEEConfiguration(hardwareInfo),
      subType,
    },
    right: {
      id,
      subType,
      provider: providerInfo.name,
      published: getTableDate(origins?.createdDate),
      ...(disabledAfter ? { disabledAfter: disabledAfter?.toString() as string } : {}),
      updated: getTableDate(origins?.modifiedDate),
    },
  };
};
