import { Item } from 'uikit/Tabs/types';
import { OfferTabs } from './types';

const getList = (isTee: boolean) => ({
  [OfferTabs.about]: 'About',
  [OfferTabs.pricing]: isTee ? 'Configurations' : 'Pricing',
  // [OfferTabs.restricted]: 'Restrictions',
});

export const getTabList = (excludes: string[], isTee = false): Item<string>[] => {
  return Object.entries(getList(isTee)).reduce((acc: Item<string>[], [k, v]) => (
    excludes.includes(k)
      ? acc
      : [...acc, {
        label: v,
        value: k,
      }]
  ), []);
};

export const getInitialTab = (list: Item<string>[], initial?: OfferTabs): string => {
  if (!list?.length) return '';
  const pricing = list.find(({ value }) => initial === value);
  return pricing?.value ?? list[0].value;
};

export const titleConfiguration = 'This is the configuration of the whole machine:';
