import { memo } from 'react';
import dynamic from 'next/dynamic';

import { Box } from 'uikitv2/Box';
import classes from './About.module.scss';
import { Configuration } from './Configuration';
import { OfferAboutProps } from './types';

const HtmlBox = dynamic(() => import('../../uikitv2/HtmlBox/HtmlBox'), { ssr: false });

export const OfferAbout = memo(({ text, configuration, titleConfiguration }: OfferAboutProps) => {
  const { slots, options } = configuration || {};

  return (
    <Box direction="column">
      {!!text && <HtmlBox text={text} ellipsis={false} className={classes.about} />}
      <Configuration slots={slots} options={options} title={titleConfiguration} />
    </Box>
  );
});
