/* eslint-disable max-len */
import { OfferType } from '@super-protocol/sdk-js';

export const text = {
  [OfferType.Data]: 'The provider is offering the following pricing plans for scaling of the data usage. Please select the plan most compatible with your intended compute configuration.',
  [OfferType.TeeOffer]: 'This compute provider is offering the following slots and options. Please select configurations most appropriate for your solution and data requirements and intended usage scenario.',
  [OfferType.Solution]: 'The provider is offering the following pricing plans for scaling of the solution. Please select the requirements most compatible with your intended compute configuration.',
  [OfferType.Storage]: '',
};

export const getTypeOf = (type: OfferType) => {
  return Object.entries(OfferType).reduce((acc, [k, v]) => ({ ...acc, [v]: k }), {})[type];
};