import { Box } from 'uikitv2/Box';
import { BilletValueProps } from './types';
import classes from './BilletValue.module.scss';

export const BilletValue = ({
  label, value, className, style,
}: BilletValueProps) => {
  return (
    <Box className={className} style={style}>
      <span className={classes.label}>{`${label}:`}</span>
      <span className={classes.value}>{value}</span>
    </Box>
  );
};
