import { memo } from 'react';

import { Box } from '../Box';
import { Icon } from '../Icon';
import { CounterProps } from './types';
import classes from './Counter.module.scss';

export const Counter = memo(({
  value, text = '',
}: CounterProps) => {
  if (!value) return null;
  return (
    <Box justifyContent="center" alignItems="center" className={classes.wrap}>
      {!!value && (
        <>
          {!!text && <span className={classes.text}>{text}</span>}
          <Icon width={6} name="multiple" className={classes.multiplier} />
        </>
      )}
      <span className={classes.value}>{value}</span>
    </Box>
  );
});
