'use client';

import { useCallback, useMemo } from 'react';
import qs from 'qs';
import { useSearchParams } from 'next/navigation';
import { useAppDispatch } from 'lib/hooks';
import { Slots } from 'lib/features/createOrderV2/types';
import { trackEvent } from 'lib/features/events/thunks';

// todo
export enum OfferTabs {
  about = 'about',
  pricing = 'pricing',
  restricted = 'restricted',
}

export interface UseOfferModalOffer {
  offerId?: string;
  slots?: Slots | null;
}

interface Query { [key: string]: string | string[] | undefined; }

export interface PrepareQueryResult extends Query {
  offer?: string;
  tab?: OfferTabs;
}

export const useOfferModal = () => {
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();
  const offerFromQuery = searchParams.get('offer');
  const tab = searchParams.get('tab');
  const offer: UseOfferModalOffer | null = useMemo(() => {
    const offerFromQueryParsed = typeof offerFromQuery === 'string' ? qs.parse(offerFromQuery) as UseOfferModalOffer : null;
    if (!offerFromQueryParsed) return null;
    const { offerId, slots } = offerFromQueryParsed || {};
    const { slot, options } = slots || {};
    if (!offerId) return null;
    return {
      offerId,
      // prepare slots types
      slots: slots
        ? {
          slot: slot?.id ? { id: slot?.id, count: +(slot?.count || 0) } : null,
          options: options?.length ? options.map(({ id, count }) => ({ id, count: +(count || 0) })) : null,
        }
        : null,
    };
  }, [offerFromQuery]);

  const changeQuery = useCallback((query: string) => {
    window.history.pushState(null, '', query);
  }, []);

  const getActualQuery = useCallback(() => {
    return [...searchParams.entries()].reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  }, [searchParams]);

  const onClose = useCallback(() => {
    const query = getActualQuery() as PrepareQueryResult;
    delete query.offer;
    delete query.tab;
    changeQuery(`?${qs.stringify(query)}`);
  }, [getActualQuery, changeQuery]);

  const prepareQuery = useCallback((offerId?: string, slots?: Slots | null, tab?: OfferTabs | null): PrepareQueryResult => {
    if (!offerId) return {};
    const obj: UseOfferModalOffer = {
      offerId,
      ...(slots ? { slots } : {}),
    };
    return {
      ...getActualQuery(),
      offer: qs.stringify(obj),
      ...(tab ? { tab } : {}),
    };
  }, [getActualQuery]);

  const openModal = useCallback((offerId?: string, slots?: Slots | null, tab?: OfferTabs | null) => {
    changeQuery(`?${qs.stringify(prepareQuery(offerId, slots, tab))}`);
    dispatch(trackEvent({
      eventType: 'open_offer_description',
      property: { offerId },
    }));
  }, [prepareQuery, changeQuery, dispatch]);

  const onChangeTab = useCallback((tab?: OfferTabs) => {
    changeQuery(`?${qs.stringify({ ...getActualQuery(), tab })}`);
  }, [getActualQuery, changeQuery]);

  return {
    onClose, offer, prepareQuery, openModal, tab: tab as OfferTabs | undefined, onChangeTab,
  };
};
