import { OfferType } from '@super-protocol/sdk-js';
import { Slots } from 'lib/features/createOrderV2/types';
import { OptionData } from 'utils/slots';

export interface Configuration {
  slots: { value: string; label: string; id: string; }[],
  options: { value: string; label: string; id: string; }[],
}

export interface LeftProps {
  name: string;
  about: string;
  restricted: string[];
  type: OfferType;
  slots: { list: OptionData[][]; id: OptionData, title: OptionData }[];
  options: { list: OptionData[][]; id: OptionData, title: OptionData }[];
  configuration?: Configuration;
  subType: string;
}

export enum OfferTabs {
  about = 'about',
  pricing = 'pricing',
  restricted = 'restricted',
}

export interface LeftComponentProps {
  data?: LeftProps;
  onAddSlot?: (slotId: string) => void;
  onDeleteSlot?: (slotId: string) => void;
  selectedSlots?: Slots | null;
  prepareSlots?: Slots | null;
  loading?: boolean;
  isCanUpdateSlot?: boolean;
  isShowCounter?: boolean;
  isTee?: boolean;
  tab?: OfferTabs;
  onChangeTab: (tab: OfferTabs) => void;
}
