import { memo, useMemo } from 'react';
import { OfferType } from '@super-protocol/sdk-js';

import { Box } from 'uikitv2/Box';
import { Billet } from './Billet';
import { PricingProps } from './types';
import {
  text, getTypeOf,
} from './helpers';
import classes from './Pricing.module.scss';

export const Pricing = memo(({
  type,
  subType,
  slots,
  options: optionsData,
  selectedSlots,
  onAddSlot,
  onDeleteSlot,
  isCanUpdateSlot,
  isShowCounter,
  loading,
}: PricingProps) => {
  const params = useMemo(() => ({
    text: type ? text[type] : '',
    title: type ? type === OfferType.TeeOffer ? 'Slots' : `${getTypeOf(type)} Requirements` : '',
  }), [type]);
  const title = useMemo(() => `${subType} Requirements`, [subType]);
  const { slot, options } = useMemo(() => selectedSlots || {}, [selectedSlots]);

  return (
    <Box direction="column" className={classes.content}>
      {!!params.text && <div>{params.text}</div>}
      {!!params.title && <div className={classes.title}>{type === OfferType.TeeOffer ? params.title : title}</div>}
      {!!slots?.length && (
        <Box className={classes.billets} direction="column">
          {slots.map((item) => {
            const isSelected = slot?.id === item?.id?.value;
            return (
              <Billet
                data={item}
                isSelected={isSelected}
                key={item.id.id}
                value={isSelected ? slot?.count : 0}
                onAdd={onAddSlot}
                onDelete={onDeleteSlot}
                isCanUpdate={isCanUpdateSlot}
                isShowCounter={isShowCounter}
                loading={loading}
              />
            );
          })}
        </Box>
      )}
      {!!optionsData?.length && (
        <>
          <div className={classes.title}>Options</div>
          <Box className={classes.billets} direction="column">
            {optionsData.map((item) => {
              const id = item?.id?.value;
              const optionCount = options?.find(({ id: optionId }) => id === optionId)?.count || 0;
              return (
                <Billet
                  data={item}
                  key={item.id.id}
                  value={optionCount}
                  isShowCounter={isShowCounter}
                  isCanUpdate={false}
                  loading={loading}
                />
              );
            })}
          </Box>
        </>
      )}
    </Box>
  );
});
