import { useMemo } from 'react';
import { Offer, TeeOffer } from 'generated/types';
import { FormContent } from 'lib/features/createOrderV2/types';
import { getOffersAndTeeOffersIds } from 'lib/features/createOrderV2/helpers';
import { useGetOffersQuery } from 'lib/features/offers';
import { useGetTeeOffersQuery } from 'lib/features/teeOffers';
import { useMemoCompare } from 'hooks/useMemoCompare';
import isEqual from 'lodash.isequal';

export const useOffersAndTeeOffersFromFormContent = (formContent: FormContent) => {
  const offersAndTeeOffers = useMemo(() => getOffersAndTeeOffersIds(formContent), [formContent]);
  const teeOffersIds = useMemoCompare(offersAndTeeOffers.teeOffers, isEqual);
  const offersIds = useMemoCompare(offersAndTeeOffers.offers, isEqual);

  const offersRespones = useGetOffersQuery(
    { filter: { ids: offersIds }, pagination: { first: null } },
    { skip: !offersIds?.length },
  );
  const teeOffersRespones = useGetTeeOffersQuery(
    { filter: { ids: teeOffersIds }, pagination: { first: null } },
    { skip: !teeOffersIds?.length },
  );
  const offers = useMemo(
    () => (offersRespones?.data?.result?.page?.edges || []).map(({ node }) => node as Offer),
    [offersRespones],
  );
  const teeOffers = useMemo(
    () => (teeOffersRespones?.data?.result?.page?.edges || []).map(({ node }) => node as TeeOffer),
    [teeOffersRespones],
  );

  return {
    loading: offersRespones?.isLoading
      || teeOffersRespones?.isLoading
      || offersRespones?.isFetching
      || teeOffersRespones?.isFetching,
    offers,
    teeOffers,
  };
};