import { memo, useMemo } from 'react';

import { Box } from 'uikitv2/Box';
import { BilletValue } from 'components/BilletValue';
import { ConfigurationProps } from './types';
import { getList } from './helpers';
import classes from './Configuration.module.scss';

export const Configuration = memo(({ slots, options, title }: ConfigurationProps) => {
  const list = useMemo(() => getList(slots, options), [slots, options]);
  if (!list.length) return null;

  return (
    <Box direction="column">
      {!!title && <Box className={classes.title}>{title}</Box>}
      <div className={classes.group}>
        {list.map(({ label, value }, idx) => (
          <BilletValue className={classes.item} key={`${label}-${idx}`} label={label} value={value} />
        ))}
      </div>
    </Box>
  );
});
